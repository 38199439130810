import React from "react";
import posthog from "posthog-js";

import Layout from "../components/layout";

export default function Doctor() {
  posthog.capture("$about");
  return (
    <Layout>
      <main
        id="content"
        role="main"
        className="main sv-landing full-page-content"
      >
        <section className="overflow-hidden">
          <h2 className="md-h2 caps pl4 mt4">
            <div>About Us</div>
          </h2>
          <div className="mb4 pl4 pr4 mt4">
            <p className="pb2">
              Simply Vision Optometry is located in downtown San Leandro, CA,
              serving the East Bay and surrounding San Francisco Bay Area. Come
              see Dr. Kalli Leung, O.D. for your eye care needs.
            </p>
            <p>We provide the following services:</p>
            <ul>
              <li>Comprehensive Eye Exams (for the whole family)</li>
              <li>Contact Lens Fittings</li>
              <li>Emergency Eye Care</li>
              <li>LASIK Co-management</li>
            </ul>
          </div>
          <h4 className="md-h4 caps pl4 mt4">
            <div>Dr. Kalli Leung, O.D.</div>
            (&#26753;&#22025;&#22025;
            <span style={{ fontSize: "80%" }}>
              &#35222;&#20809;&#23416;&#21338;&#22763;
            </span>
            )
          </h4>
          <div className="mb4 pl4 pr4 mt4">
            <p>
              Dr. Leung is licensed by the California State Board of Optometry,
              with TLG certification.
            </p>
            <p>Education:</p>
            <ul>
              <li>Doctor of Optometry, New England College of Optometry</li>
              <li>
                Bachelor of Science in Biochemistry and Molecular Biology,
                University of California, Davis
              </li>
            </ul>
            <p>Member of:</p>
            <ul>
              <li>California Optometric Association</li>
              <li>American Optometric Association</li>
            </ul>
            <p>
              Dr. Leung was born in Hong Kong and is fluent in English,
              Cantonese, and clinical Mandarin. She has been working
              professionally in the Bay Area since 2008.
            </p>
          </div>
        </section>
      </main>
    </Layout>
  );
}
